import React from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import styled from "styled-components";
import { device, colors, transitions, animations, StyledFonts, InterFallbackHeading } from "../components/Layout/theme";
import AlertMessage from "../components/AlertMessage";
import FillLeftButton from "../components/FillLeftButton";

const PageWrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 80vh;
`;

const ContentWrapper = styled.div`
  width: 84vw;
  max-width: 500px;
  padding-bottom: 15vh;
`;

const Title = styled.h1`
  ${StyledFonts.mainH3}
  font-size: clamp(4rem, 18vw, 7rem);

  @media ${device.tablet} {
    ${StyledFonts.mainH3}
    font-size: clamp(7rem, 10vw, 7.5rem) !important;
  }
`;

const AlertMessageWrapper = styled.div`
  border-top: 1px solid ${colors.white};
  border-bottom: 1px solid ${colors.white};
  margin: 1rem 0;
  padding: 0.5rem 0;

  p {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  a span {
    font-size: 3.2rem;

    [lang="hu-hu"] &,
    [lang="pl-pl"] &,
    [lang="vi-vn"] & {
      ${InterFallbackHeading};
      font-size: 2.1rem;
    }

    [lang="cs-cz"] &,
    [lang="ro-ro"] &,
    [lang="tr-tr"] & {
      ${InterFallbackHeading};
      font-size: 2.4rem;
    }

    [lang="el-gr"] &,
    [lang="ja-jp"] &,
    [lang="ko-kr"] &,
    [lang="ru-ru"] &,
    [lang="th-th"] &,
    [lang="zh-tw"] & {
      font-size: 2.6rem;
    }
  }
`;

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation("translations");
  const { language } = useI18next();

  return (
    <>
      <Layout>
        <GatsbySeo title={t("pageNotFound.title")} noindex={true} />
        <PageWrapper>
          <ContentWrapper>
            <Title>404</Title>
            <AlertMessageWrapper>
              <AlertMessage isActive={true} textColor="white">
                {t("pageNotFound.message")}
              </AlertMessage>
            </AlertMessageWrapper>
            <ButtonWrapper className="sm">
              <FillLeftButton text={t("pageNotFound.button.text")} link={`/${language}`} />
            </ButtonWrapper>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
